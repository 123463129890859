import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'font-awesome/css/font-awesome.css';

import {postRequest} from "./utils/api";
import {putRequest} from "./utils/api";
import {getRequest} from "./utils/api";
import {deleteRequest} from "./utils/api";
import {initMenu} from "./utils/menus";
import {downloadRequest} from "./utils/download";


/*关闭浏览器控制台关于环境的一个相关的提示改成true以后会提示你这是一个开发的环境*/
Vue.config.productionTip = false
Vue.use(ElementUI,{size:'small'});
//插件形式使用请求
Vue.prototype.postRequest = postRequest;
Vue.prototype.putRequest = putRequest;
Vue.prototype.getRequest = getRequest;
Vue.prototype.deleteRequest = deleteRequest;
Vue.prototype.downloadRequest = downloadRequest;

router.beforeEach((to,from,next)=>{
    if(window.sessionStorage.getItem('tokenStr')){
        //console.log(to);
        //console.log(from);
        //next(false); 表示要中断当前的一个跳转
        //next('/'); 表示让它手动跳到登录页面
        initMenu(router,store);
        if(!window.sessionStorage.getItem('user')){
            //判断用户信息是否存在
            return getRequest('/admin/info').then(resp=>{
                if(resp){
                    //存入用户信息
                    window.sessionStorage.setItem('user',JSON.stringify(resp));
                    store.commit('INIT_ADMIN',resp);
                    next(); /*千万不要忘记next();只有next();你才能进行跳转*/
                }
            })
        }
        next();
    }else{
        if(to.path == '/'){
            next();
        }else{
            next('/?redirect='+to.path); /*这有问号才是传参*/
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App) /*渲染App这个组件*/
}).$mount('#app') /*手动挂载*/



























