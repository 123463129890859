import {getRequest} from "./api";

export const initMenu = (router,store)=>{
    if(store.state.routes.length>0){
        return;
    }

    getRequest('/system/config/menu').then(data=>{
        if(data){
            //格式化好了的Router
            let fmtRoutes = formatRoutes(data);
            /*格式化好后把它放到路由里*/
            router.addRoutes(fmtRoutes);
            //将数据存入vuex
            store.commit('initRoutes',fmtRoutes);
            //连接WebSocket
            store.dispatch('connect');
        }
    })
}

export const formatRoutes = (routes) =>{
    let fmtRoutes = [];
    routes.forEach(router=>{
        let{
            path,
            component,
            name,
            iconCls,
            children,
        } = router;
        if(children && children instanceof Array){ /*如果你的children存在，并且属于一个数组*/
            //递归
            children = formatRoutes(children)/*这里我们用递归，因为children本质上也是我们的路由*/
        }
        let fmtRouter = { /*这里我们做一个格式化*/
            path:path,
            name:name,
            iconCls:iconCls,
            children:children,
            component(resolve){
                if(component.startsWith('Home')){
                    require(['../views/'+component+'.vue'],resolve);
                } else if (component.startsWith('Emp')) {
                    require(['../views/emp/' + component + '.vue'], resolve); /*这里有点那种懒加载的意思*/
                } else if (component.startsWith('Per')) {
                    require(['../views/per/' + component + '.vue'], resolve);
                } else if (component.startsWith('Sal')) {
                    require(['../views/sal/' + component + '.vue'], resolve);
                } else if (component.startsWith('Sta')) {
                    require(['../views/sta/' + component + '.vue'], resolve);
                } else if (component.startsWith('Sys')) {
                    require(['../views/sys/' + component + '.vue'], resolve);
                }
            }
        }
        /*格式化好后我们再把它放到我们格式化的数组里面*/
        fmtRoutes.push(fmtRouter);
    });
    return fmtRoutes;
}































