import axios from "axios";

const service = axios.create({
    responseType: 'arraybuffer'
})

service.interceptors.request.use(config => {
    config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr');
    return config;
}, error => {
    console.log(error);
})

service.interceptors.response.use(resp => {
    const headers = resp.headers; /*先拿我们响应的头部信息*/
    let reg = RegExp(/application\/json/); /*定义一个正则表达式，
    因为虽然我们是以流的形式返回，但是也有可能是json字符串的形式返回，因为我们现在是前后端分离
    后端返回的数据大部分都是json字符串，只有像这种下载文件，这种特殊的接口，才会返回流的形式
    所以我们要去做一些相应的判断*/
    if (headers['content-type'].match(reg)) { /*如果匹配了则说明是一个普通的json字符串请求*/
        resp.data = unitToString(resp.data); /*我们再写一个方法把它转成我们的string类型
        就是正常返回我们json字符串*/
    } else { /*否则就是返回了stream流*/
        let fileDownload = require('js-file-download');
        let filename = headers['content-disposition'].split(';')[1].split('filename=')[1];
        let contentType = headers['content-type'];
        filename = decodeURIComponent(filename); /*先给文件名做一下格式的转换，如果文件名是中文的很有可能会乱码*/
        fileDownload(resp.data,filename,contentType);
    }
}, error => {
    console.log(error);
})

function unitToString(unitArray) {
    let encodedString = String.fromCharCode.apply(null, new Uint8Array(unitArray)); /*一个是编码*/
    let decodedString = decodeURIComponent(escape(encodedString)); /*一个是解码*/
    return JSON.parse(decodedString);
}

/*一般情况下，涉及到下载的都是用get请求*/
let base = '';
    export const downloadRequest=(url,params)=>{
        return service({
            method:'get',
            url:`${base}${url}`,
            data:params
        })
    }

/*我们定义好了之后还要把整体暴露出去，这样请求就封装好了
* 使用的时候就是以插件的形式使用*/
export default service;



























