import axios from 'axios'
import { Message } from 'element-ui';
import router from '../router'

//请求拦截器
axios.interceptors.request.use(config=>{
    //如果存在token，请求携带这个token
    if(window.sessionStorage.getItem('tokenStr')){
        config.headers['Authorization'] = window.sessionStorage.getItem('tokenStr');
    }
    return config;
},error => {
    console.log(error);
})

//封装了一个响应拦截器
axios.interceptors.response.use(success=>{ //是否成功调用到我们的这个接口--是否200
    //业务逻辑错误
    if(success.status && success.status == 200){
        if(success.data.code==500||success.data.code==401||success.data.code==403){ //所有的失败走这个if
            Message.error({message:success.data.message});
            return;
        }
        if(success.data.message){ //判断成功了以后有没有返回信息
            Message.success({message:success.data.message});
        }
    }
    return success.data;
},error => { //后端接口没调到
    if(error.response.code==504||error.response.code==404){
        Message.error({message:'服务器被吃了o(╥﹏╥)o'});
    }else if(error.response.code==403){
        Message.error({message:'权限不足，请联系管理员！'});
    }else if(error.response.code=401){ //没登录
        Message.error({message:'尚未登录，请登录！'});
        router.replace('/'); //斜杠表示我们的登录页
    }else{
         if(error.response.data.message){ //其他中，如果有信息，把信息打印出来
             Message.error({message:error.response.data.message});
         }else{ //如果什么响应信息都没有则未知错误
             Message.error({message:'未知错误！'});
         }
    }
    return; //error返回一个空
});

//接下来写请求

let base = 'api';

//传送json格式的post请求
export const postRequest= (url,params)=>{
    return axios({
        method:'post',
        url:`${base}${url}`,
        data:params
    })
}

//传送json的put请求
export const putRequest=(url,params)=>{
    return axios({
        method: 'put',
        url: `${base}${url}`,
        data: params
    })
}

//传送json的get请求
export const getRequest=(url,params)=>{
    return axios({
        method: 'get',
        url: `${base}${url}`,
        data: params
    })
}

//传送json的delete请求
export const deleteRequest=(url,params)=>{
    return axios({
        method: 'delete',
        url: `${base}${url}`,
        data: params
    })
}




