import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import FriendChat from "../views/chat/FriendChat";
import Home from "../views/Home";
import AdminInfo from "../views/AdminInfo";

Vue.use(VueRouter) /*去安装这个路由*/

const routes = [ /*配置路由*/
    {
        path: '/',
        name: 'Login',
        component: Login,
        hidden: true /*加了hidden标签后，遍历路由的时候就不会被循环到*/
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        children: [
            {
                path: '/chat',
                name: '在线聊天',
                component: FriendChat
            },
            {
                path: '/userInfo',
                name: '个人中心',
                component: AdminInfo
            }
        ]
    }
]

const router = new VueRouter({
    routes
})

export default router /*导出*/
